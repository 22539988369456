//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { supportsTouchEvents } from "detect-it";

export default {
  computed: {
    /* istanbul ignore next */
    isTouch: () => supportsTouchEvents,
  },

  methods: {
    openLink(link) {
      window.location.href = link;
    },
  },

  props: {
    /** Target URL for nuxt link */
    to: {
      type: String,
      default: undefined,
      required: false,
    },
    /** Target URL for links outside nuxt */
    href: {
      type: String,
      default: undefined,
      required: false,
    },
    /** UI title */
    title: {
      type: String,
      required: false,
    },
    /** CSS class - button theme
     * **blue**, **dark-blue**, **orange**, **silver**, **light-grey**, **bordered-grey**, **red**, **red-inverted**, **white**
     */
    theme: {
      type: String,
      required: false,
      default: "blue",
    },
    /** CSS class - button size. Classes can be used together.
     * **small**, **medium**, **large**, **x-large**, **full**, **long**
     */
    size: {
      type: String,
      required: false,
      default: "",
    },
    /** Icon position, relative to text. The icon element is injected via a slot.
     * **left**, **right**, **center**
     */
    icon: {
      type: String,
      required: false,
      default: "",
    },
    /** CSS class that is applied to the icon element on button hover */
    iconHover: {
      type: String,
      required: false,
      default: "",
    },
    /** The class to apply (missing info) */
    iconActive: {
      type: String,
      required: false,
      default: "",
    },
    /** Anchor tag **target** attribute */
    target: {
      type: String,
      required: false,
      default: "_self",
    },
    /** Disable the button. This will prevent it from emmiting events and apply a disabled UI State */
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    /** Submit button */
    type: {
      type: String,
      required: false,
      default: undefined,
    },

    /** Loading button */
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
