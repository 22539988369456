var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"button-wrapper"},[(_vm.to)?[_c('nuxt-link',{class:[
        _vm.size,
        { 'is-touch-device': _vm.isTouch },
        'btn',
        'btn-' + _vm.theme,
        { disable: _vm.isDisabled } ],attrs:{"to":_vm.to},on:{"click":function($event){return _vm.$emit('click', $event.target)}}},[_c('div',{class:{ 'has-icon': _vm.icon }},[(_vm.icon === 'left')?_c('span',{staticClass:"icon icon-left",class:[{ iconHover: !_vm.isTouch }, _vm.iconActive]},[_vm._t("icon-left")],2):_vm._e(),_vm._v("\n        "+_vm._s(_vm.title)+"\n        "),(_vm.icon === 'right')?_c('span',{staticClass:"icon icon-right",class:[{ iconHover: !_vm.isTouch }, _vm.iconActive]},[_vm._t("icon-right")],2):_vm._e(),_vm._v(" "),(_vm.icon === 'center')?_c('span',{staticClass:"icon",class:[{ iconHover: !_vm.isTouch }, _vm.iconActive]},[_vm._t("icon-center")],2):_vm._e()])])]:[_c('button',{class:[
        _vm.size,
        { 'is-touch-device': _vm.isTouch },
        'btn',
        'btn-' + _vm.theme,
        { disable: _vm.isDisabled } ],attrs:{"type":_vm.type,"disabled":_vm.isDisabled},on:{"click":function($event){_vm.href ? _vm.openLink(_vm.href) : ''}}},[(_vm.loading)?[_c('spinner',{attrs:{"size":"4"}})]:_vm._e(),_vm._v(" "),(!_vm.loading)?_c('div',{class:{ 'has-icon': _vm.icon }},[(_vm.icon === 'left')?_c('span',{staticClass:"icon icon-left",class:[{ iconHover: !_vm.isTouch }, _vm.iconActive]},[_vm._t("icon-left")],2):_vm._e(),_vm._v("\n        "+_vm._s(_vm.title)+"\n        "),(_vm.icon === 'right')?_c('span',{staticClass:"icon icon-right",class:[{ iconHover: !_vm.isTouch }, _vm.iconActive]},[_vm._t("icon-right")],2):_vm._e(),_vm._v(" "),(_vm.icon === 'center')?_c('span',{staticClass:"icon",class:[{ iconHover: !_vm.isTouch }, _vm.iconActive]},[_vm._t("icon-center")],2):_vm._e()]):_vm._e()],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }